<template>
  <DialogFormWrapper>
    <template #form>
      <v-checkbox
        v-model="attachUnpaidInvoices"
        class="mt-0"
        :disabled="isProcessing"
        label="Załącz zestawienie nieopłaconych faktur"
        hide-details
      />
      <v-checkbox
        v-model="withDebrisInfo"
        class="mt-2"
        :disabled="isProcessing"
        label="Pokaż informacje o odpadach w zestawieniu"
        hide-details
      />
      <v-checkbox
        v-model="withInvoiceReport"
        class="mt-2"
        :disabled="isProcessing"
        label="Pokaż informacje o kursach w zestawieniu"
        hide-details
      />
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="isProcessing"
        @click="submitRefreshPDF"
      >
        Odśwież PDF
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data() {
    return {
      attachUnpaidInvoices: false,
      withDebrisInfo: false,
      withInvoiceReport: false
    }
  },
  mounted() {
    this.withDebrisInfo = this.invoice.withDebrisInfo
    this.withInvoiceReport = this.invoice.withInvoiceReport
  },
  computed: {
    ...mapState({
      isProcessing: state => state.invoice.isProcessing,
      invoice: state => state.layout.dialog.item,
    })
  },
  methods: {
    ...mapActions({
      refreshPDF: 'invoice/refreshPDF',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    submitRefreshPDF() {
      const { withDebrisInfo, withInvoiceReport, attachUnpaidInvoices } = this
      const payload = { id: this.invoice.id, attachUnpaidInvoices, withDebrisInfo, withInvoiceReport }
      this.refreshPDF(payload).then(() => {
        this.closeDialog()
        this.showSnackbar({ message: ['Odświeżono PDF'] })
      })
    }
  }
}
</script>
